<template>
  <li class="node-tree  pl-6 mb-3 mt-3">
    <div class="label bg-teal-300 rounded-lg p-2 flex-row">
        <div> 
        <i v-if="node.children && node.children.length" class="fa fa-chevron-down"></i> 
        <span class="cursor-pointer underline px-2" v-on:click="itemclickfunc(node)" v-if="enableLink && node.id!=1">{{ node.name }}</span>
        <span class="px-2" v-if="!enableLink || node.id==1">{{node.name}}</span>
        </div>
        <div class="absolut px-2 justify-end" v-if="enableEdit">
            <button class="pr-2" v-on:click="newitemfunc(node)"><i class="fa fa-file"></i></button>
            <button class="pr-2" v-on:click="edititemfunc(node)" v-if="node.id!=1"><i class="fa fa-edit"></i></button>
            <button class="pr-2" v-on:click="delitemfunc(node)" v-if="node.id!=1"><i class="fa fa-trash"></i></button>
        </div>
    </div>

    <ul v-if="node.children && node.children.length" class="mt-0">
      <node v-for="child in node.children" :node="child" :key="child.id" @newitem="newitemfunc" @delitem="delitemfunc" @edititem="edititemfunc" @itemclick="itemclickfunc" :enable-edit="enableEdit" :enable-link="enableLink"></node>
    </ul>
  </li>
</template>

<script>
export default {
  name: "node",
  props: {
    node: Object,
    enableEdit:{},
    enableLink:{}
  },
  emits: {
      newitem:null,
      delitem:null,
      edititem:null,
      itemclick:null
  },
  methods: {
      newitemfunc:function(node){
        this.$emit('newitem', node);
      },
      delitemfunc:function(node){
        this.$emit('delitem', node)
      },
      edititemfunc:function(node){
        this.$emit('edititem', node)
      },
      itemclickfunc:function(node)
      {
        this.$emit('itemclick',node);
      }
  }
};
</script>