<template>
<div>
  <object-edit-form v-bind:updateQuery="updateQuery" v-bind:selectQuery="selectQuery" v-bind:typeName="typeName"/>
</div>
</template>

<script>
import gql from 'graphql-tag';
import ObjectEditForm from "@/components/ObjectEditForm/ObjectEditForm";

export default {
    components: {
      ObjectEditForm
    },
    data(){ 
      return {
        typeName:"dcgql.Models.Category",
        updateQuery: gql`
            mutation($updateObject:CategoryInput)
            {updatedObject:addCategory(category:$updateObject)
            {
              name,
              description,
              categoryId
            }}
            `,
        selectQuery:gql`query  {
          objectItem:Categories (id:1){pkid, name , description}
          }`
      }
    }
};
</script>
