<template>
<div>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0 mt-10">
    <div class="rounded-t bg-white mb-0 px-6 py-6">   
      <div class="text-center flex justify-between">   
      <h6 class="text-gray-800 text-xl font-bold">药方</h6>
      </div>
    </div>
    <div class="lg:flex">
        <div class="lg:flex-auto px-4 py-10 pt-5 eform lg:w-1/3 min-h-full">
            <category-tree  :enable-edit="enableTreeEdit" :enable-link="enableTreeLink"  @itemclick="itemclick"></category-tree>     
        </div>
        <div class="lg:flex-auto px-4 py-10 pt-5 eform lg:w-2/3">
            <div v-if="currentNode && currentNode.id">        
                <div class="flex-row mb-5 p-0 bg-gray-400 p-2 rounded-1-lg">
                    <h2>
                        {{currentNode.name}}                     
                        <button v-on:click="newPrescription" class="px-1 py-1 m-2 rounded-lg border border-red-600 bg-red-600 text-white text-xs max-w-max shadow-sm hover:shadow-md"><i class="fa fa-file"></i> 新处方</button>
                    </h2>
                    <div class="bg-white">{{currentNode.description}}</div>
                </div>
                <ul v-if="prescriptions && prescriptions.length">
                    <li v-for="prescription in prescriptions" :key="prescription.id" >
                        <div class="rounded-lg flex-row bg-white mb-5">
                            <div class="bg-gray-300 p-2 rounded-t-lg ">
                                处方：{{prescription.name}}
                                <button v-on:click="edititem(prescription)" class="px-2"><i class="fa fa-edit"></i></button>
                                <button v-on:click="deleteitem(prescription)"><i class="fa fa-trash"></i></button>
                            </div>
                            <div class="p-2">
                                {{prescription.description}}
                            </div>
                            <div class="p-2">
                                <button v-on:click="addElement(prescription.id)" class="px-1 py-1 m-2 rounded-lg border border-red-600 bg-red-600 text-white text-xs max-w-max shadow-sm hover:shadow-md"><i class="fa fa-plus"></i> 添加药</button>
                                <ul v-if="prescription.Elements && prescription.Elements.length" class="sm:grid sm:gap-4 sm:grid-cols-3">
                                    <li v-for="element in prescription.Elements" :key="element.id" class="rounded-lg bg-indigo-100 min-w-min w-full">
                                        <div class="flex flex-column justify-between p-2"><div class="font-extrabold">{{element.name}}</div><div>{{element.unitamount}}{{element.unit}}</div></div>
                                        <div class="bg-gray-150 p-2">{{element.description}}</div>
                                        <div class="bg-gray-100 rounded-b-lg ">
                                            <button v-on:click="editElement(element)" class="px-2"><i class="fa fa-edit"></i></button>
                                            <button v-on:click="removeElement(element)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="rounded-b bg-white mb-0 px-6 py-6">   
        <div class="text-center flex justify-between">
            <h6>&nbsp;</h6>   
        </div>
    </div>
  </div>
    <!--dialog-->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="isEdit">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <i class="fa fa-edit"></i>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            编辑药方
                        </h3>
                        <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          <vue-form-generator :schema="schema" :model="model"></vue-form-generator>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button" v-on:click="saveItem()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    保存
                    </button>
                    <button type="button" v-on:click="disableEdit()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    取消
                    </button>
                </div>
            </div>
        </div>
    </div>  

    <!--Element dialog-->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="isElementEdit">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <i class="fa fa-edit"></i>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            编辑药
                        </h3>
                        <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          <vue-form-generator :schema="elementSchema" :model="currentElement"></vue-form-generator>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button" v-on:click="saveElementItem()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    保存
                    </button>
                    <button type="button" v-on:click="disableElementEdit()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    取消
                    </button>
                </div>
            </div>
        </div>
    </div>  

</div>
</template>
<script>
import CategoryTree from "@/components/CategoryTree/CategoryTree";
import gql from 'graphql-tag';
import {PRESCRIPTION_BY_CATEGORY_QUERY, INSERT_PRESCRIPTION,DELETE_PRESCRIPTION, DELETE_ELEMENT,INSERT_ELEMENT,UPDATE_ELEMENT} from "@/queries/queries";
export default {
  components:{
      CategoryTree
  },
  methods:{
    newPrescription:function()
    {
        this.model.name = "";
        this.model.description = "";
        this.model.categoryid = this.currentNode.id;
        this.model.id = 0;
        this.isEdit = true;
    },
    disableElementEdit:function(){
        this.isElementEdit = false;
    },
    addElement:function(prescriptionId)
    {
        this.currentElement.prescriptionid = prescriptionId;
        this.currentElement.name = "";
        this.currentElement.description = "";
        this.currentElement.parttype = "";
        this.currentElement.unit = "";
        this.currentElement.unitamount = "";
        this.currentElement.id = 0;
        this.model.categoryid = this.currentNode.id;
        this.isElementEdit=true;
    },
    editElement:function(element)
    {
        this.currentElement.prescriptionid = element.fkprescriptionid;
        this.currentElement.name = element.name;
        this.currentElement.description = element.description;
        this.currentElement.parttype = element.parttype;
        this.currentElement.unit = element.unit;
        this.currentElement.unitamount = element.unitamount;
        this.currentElement.id = element.id;
        this.model.categoryid = this.currentNode.id;
        this.isElementEdit=true;
    },
    removeElement:function(element)
    {
        console.log(element);
        this.model.categoryid = this.currentNode.id;
        this.currentElement = element;
        this.currentElement.prescriptionid = element.fkprescriptionid;
        const q = DELETE_ELEMENT;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{

                    id: this.currentElement.id
                },
                update:(store,{data:delete_Elements_by_pk})=>{
                    const data = store.readQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        }
                        });

                    const prescription = data.Prescriptions.find(t=> t.id==this.currentElement.prescriptionid);

                    prescription.Elements = prescription.Elements.filter(t=>{return t.id!=delete_Elements_by_pk.delete_Elements_by_pk.id});

                    store.writeQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        },
                        data:data
                    });
                    this.loadPrescriptions(this.model.categoryid);
                } 
            }
        );
    },
    deleteitem:function(prescription)
    {
        this.model = prescription;
        this.model.categoryid = this.currentNode.id;
        const q = DELETE_PRESCRIPTION;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{

                    id: this.model.id
                },
                update:(store,{data:delete_Prescriptions_by_pk})=>{
                    const data = store.readQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        }
                        });

                    data.Prescriptions = data.Prescriptions.filter(t=> { return t.id!=delete_Prescriptions_by_pk.delete_Prescriptions_by_pk.id});

                    store.writeQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        },
                        data:data
                    });
                    this.loadPrescriptions(this.model.categoryid);
                    this.disableEdit();
                }
            }
        );
    },
    saveElementItem:function(){
        if (this.currentElement.id==0)
            this.insertElementItem();
        else
            this.updateElementItem();
    },
    insertElementItem:function(){
        const q = INSERT_ELEMENT;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{
                    prescriptionid: this.currentElement.prescriptionid,
                    name: this.currentElement.name,
                    description:this.currentElement.description,
                    parttype: this.currentElement.parttype,
                    unit: this.currentElement.unit,
                    unitamount:this.currentElement.unitamount

                },
                update:(store,{data:insert_Elements_one})=>{
                    const data = store.readQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        }
                        });
                    console.log(data);
                    console.log(insert_Elements_one);
                    const prescription = data.Prescriptions.find(t=> t.id===this.currentElement.prescriptionid);

                    prescription.Elements.push(insert_Elements_one);

                    store.writeQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        },
                        data:data
                    });
                    this.loadPrescriptions(this.model.categoryid);
                    this.disableElementEdit();
                }   
        }
        );
    },
    updateElementItem:function(){
        const q = UPDATE_ELEMENT;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{
                    id: this.currentElement.id,
                    name: this.currentElement.name,
                    description:this.currentElement.description,
                    parttype: this.currentElement.parttype,
                    unit: this.currentElement.unit,
                    unitamount:this.currentElement.unitamount
                },
                update:(store,{data:update_Elements_by_pk})=>{
                    const data = store.readQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        }
                        });

                    const prescription = data.Prescriptions.find(t=> t.id===this.currentElement.prescriptionid);

                    const elt = prescription.Elements.find(t=> t.id===this.currentElement.id);
                    elt.name = update_Elements_by_pk.update_Elements_by_pk.name;

                    store.writeQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        },
                        data:data
                    });
                    this.loadPrescriptions(this.model.categoryid);
                    this.disableElementEdit();
                }   
            }
        );
    },
    edititem:function(node){
        this.model.name = node.name;
        this.model.description = node.description;
        this.model.id = node.id;
        this.model.categoryid = this.currentNode.id;
        this.isEdit = true;
    },
    saveItem:function(){
        if (this.model.id==0)
            this.insertItem();
        else
            this.updateItem();
    },
    insertItem:function(){
        const q = INSERT_PRESCRIPTION;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{
                    categoryid: this.model.categoryid,
                    name: this.model.name,
                    description:this.model.description 
                },
                update:(store,{data:updatedObject })=>{
                    const data = store.readQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        }
                        });

                    data.Prescriptions.push(updatedObject.updatedObject)

                    store.writeQuery({
                        query: PRESCRIPTION_BY_CATEGORY_QUERY,
                        variables: {
                            categoryid: this.model.categoryid
                        },
                        data:data
                    });
                    this.disableEdit();
                }
            }
        );
    },
    updateItem:function(){
        const q = gql`
        mutation($name:String,$description:String,$id:bigint!) {
            update_Prescriptions_by_pk(pk_columns: {id: $id}, _set: {description: $description, name: $name}) {
                description
                id
                name
            }
        }
        `;
        this.$apollo.mutate(
            {
                mutation: q,
                variables:{
                    name: this.model.name,
                    description:this.model.description,
                    id: this.model.id
                }
            }
        ).then((data)=>{
            this.model.name = data.name;
            this.model.id = data.id;
            this.disableEdit();
        });
    },
    itemclick:function(node){
        this.currentNode=node;
        this.loadPrescriptions(node.id);
    },
    disableEdit:function(){
        this.isEdit = false;
    },
    loadPrescriptions:function(id){
        const q = PRESCRIPTION_BY_CATEGORY_QUERY;
        this.$apollo.query(
        {
            query: q,
            variables:{
                categoryid: id
            }
        }
        ).then((data)=>{
            this.prescriptions = data.data.Prescriptions;
        }); 
    }
  },
  data() {
    return {
      isEdit: false,
      isElementEdit: false,
      enableTreeEdit:false,
      enableTreeLink:true,
      prescriptions: [],
      categories:{},
      currentNode:{},
      model: {},
      currentElement: {},
      elementSchema:{
          fields : [
              {
                type:"input",
                inputType:"text",
                label:"名字",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Name",
                model: "name",
                disabled:false
              },
              {
                type:"input",
                inputType:"number",
                label:"量",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"unit amount",
                model: "unitamount",
                disabled:false
              },
              {
                type:"select",
                inputType:"text",
                label:"量单位",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"unit",
                model: "unit",
                disabled:false,
                values:["克","包","钱","两","个"]
              },
              {
                type:"select",
                inputType:"text",
                label:"主药",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"unit",
                model: "parttype",
                disabled:false,
                values:["Main","Optional"]
              },
              {
                type:"MD",
                inputType:"MD",
                label:"描述",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Description",
                model: "description",
                disabled:false
              }
            ]          
      },
      schema: {
          fields : [
              {
                type:"input",
                inputType:"text",
                label:"名字",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Name",
                model: "name",
                disabled:false
              },
              {
                type:"MD",
                inputType:"MD",
                label:"描述",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Description",
                model: "description",
                disabled:false
              }

            ]
      }
    };
  }
};
</script>