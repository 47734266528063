<template>
<div>
  <edit-form v-bind:model="model" v-bind:typeName="typeName" @saveitem="save"/>
</div>
</template>

<script>
import EditForm from "@/components/EditForm/EditForm";

export default {
    components: {
      EditForm,
    },
    props:{
        typeName: {},
        updateQuery: {},
        selectQuery: {},
    },
    data(){ 
      return {
        model:{}
      }
    },
    computed:{
      updatedObject:function(){
        let m = this.model;
        delete m.__typename;
        return m;
      },
      selectQuery2: function(){
          return this.selectQuery;
      }
    },
    created:function(){
        this.$apollo.query(
            {
                query: this.selectQuery,                
            }
        ).then((data)=>{
            this.model = data.data.objectItem;
        });     
    },
    methods:{
      save(){
        this.$apollo.mutate(
          {
            mutation: this.updateQuery,
            variables:{
              updateObject: this.updatedObject
            }
          }
        ).then((data)=>{
          this.model = data.data.updatedObject;
        });
      }
    },

};
</script>
