<template>
  <div>
    <router-view />
</div>
</template>
<script>
export default {
  name: "std-layout",
  components: {
    
  },
};
</script>
