import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'

import Admin from "@/layout/Admin.vue";
import Std from "@/layout/Std.vue";

import Dashboard from "@/views/admin/Dashboard.vue";

import SysSettings from "@/views/admin/sys.settings.vue";
import Categories from "@/views/admin/Categories.vue";
import Prescriptions from "@/views/admin/Prescriptions.vue";

import Tables from "@/views/pages/Table.vue";
import "@fortawesome/fontawesome-free/css/all.min.css";

import '@/assets/css/style.css';
import { createProvider } from './vue-apollo';

import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';

import Editor from 'v-markdown-editor';
import 'v-markdown-editor/dist/v-markdown-editor.css';

import MDField from "@/components/MDField/MDField.vue";

import { domain, clientId } from "../auth_config";
import { Auth0Plugin } from "./auth";
import { authGuard } from "./auth/authGuard";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    beforeEnter: authGuard,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/categories",
        component: Categories
      },
      {
        path:"/admin/syssettings",
        component: SysSettings
      },
      {
        path:"/admin/prescriptions",
        component: Prescriptions
      }
    ],
  },
  {
    path: "/tables",
    redirect: "/pages/tables",
    component: Std,
    meta: { requiresAuth: true },
    children:[
      {
        path:"/pages/tables",
        component: Tables
      }
    ],
  },
  {      
    path: "/",
    redirect: "/admin/dashboard",
  },
];
Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Editor);

Vue.component("fieldMD", MDField);

Vue.use(VueFormGenerator);

const router = new VueRouter({
  routes,
});

new Vue({
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
