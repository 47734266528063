import gql from 'graphql-tag';

export const ALL_CATEGORIES_QUERY = gql`query{
    Categories(order_by: {parent: asc}) {
        description
        name
        parent
        id
    }
}`;

export const PRESCRIPTION_BY_CATEGORY_QUERY = gql`query($categoryid:bigint){
    Prescriptions(where: {PrescriptionCategories: {Category: {id: {_eq: $categoryid}}}}) {
        id
        name
        description,
        Elements {
            description
            fkprescriptionid
            id
            name
            parttype
            unit
            unitamount
        }
    }
}`;

export const DELETE_CATEGORY_MUTATION = gql`
    mutation($pkid: bigint!)
    {
        updatedObject:delete_Categories_by_pk(id:$pkid)
        {
        name,
        description,
        id,
        parent
        }
    }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
    mutation($pkid: Categories_pk_columns_input!,$updateObject:Categories_set_input)
    {
        updatedObject:update_Categories_by_pk(pk_columns:$pkid,_set:$updateObject)
        {
            name,
            description,
            id,
            parent
        }
    }
`;

export const INSERT_CATEGORY_MUTATION = gql`
    mutation($updateObject: Categories_insert_input!)
    {
        updatedObject:insert_Categories_one(object:$updateObject)
        {
        name,
        description,
        id,
        parent
        }
    }
`;
export const INSERT_PRESCRIPTION = gql`
    mutation($name:String,$description:String,$categoryid:bigint) 
    {
        updatedObject:insert_Prescriptions_one(object: {name: $name, description: $description, PrescriptionCategories: {data: {fkCategoryId: $categoryid}}}) 
        {
            id,
            name,
            description,
            Elements {
                description
                fkprescriptionid
                id
                name
                parttype
                unit
                unitamount
            }
        }
    }
`;

export const DELETE_PRESCRIPTION =gql`
    mutation ($id:bigint!) 
    {
        delete_PrescriptionCategories(where: {Prescription: {id: {_eq: $id}}})
        {returning {
        fkCategoryId
        }}
        delete_Prescriptions_by_pk(id: $id) {
        id
    }
    }
`;
export const DELETE_ELEMENT = gql`
mutation ($id:bigint!) 
{delete_Elements_by_pk(id: $id) {
   id
}
}
`;

export const INSERT_ELEMENT = gql`
mutation($name:String,$description:String,$prescriptionid:Int,$parttype:String,$unit:String,$unitamount:Int) {
    insert_Elements_one(object: {description: $description, fkprescriptionid: $prescriptionid, name: $name, parttype: $parttype, unit: $unit, unitamount: $unitamount}) {
        description
        id
        name
        parttype
        unit
        unitamount
        fkprescriptionid
    }
}
`;

export const UPDATE_ELEMENT = gql`
mutation($name:String,$description:String,$id:bigint!,$parttype:String,$unit:String,$unitamount:Int) {
    update_Elements_by_pk(pk_columns: {id: $id}, _set: {description: $description, name: $name, parttype: $parttype, unit: $unit, unitamount: $unitamount}) {
        description
        fkprescriptionid
        id
        name
        parttype
        unit
        unitamount
    }
}
`;