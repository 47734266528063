<!-- MDField.vue -->
<template>
<div class="fieldmd">
  <markdown-editor theme="success" v-model="value" :readonly="schema.readonly" height="150px"></markdown-editor>
</div>
</template>
<style>
.fieldmd >.v-md-container >.v-md-toolbar >.btn-group
{
  display: inline-flex;
  vertical-align: middle;
}
.fieldmd >.v-md-container >.v-md-toolbar >.btn-group >.btn-success
{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: .95rem;
    line-height: 1.5;
    color: #fff;
    background-color: #2dce89;
    border-color: #2dce89;
    border-radius: 0;
    margin-right: 0;
}
.fieldmd >.v-md-container >.v-md-toolbar > .btn-group > .btn-success:first-child
{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.fieldmd >.v-md-container >.v-md-toolbar > .btn-group > .btn-success:last-child
{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>

<script>
   import { abstractField } from "vue-form-generator";

   export default {
         mixins: [ abstractField ]
   };
</script>