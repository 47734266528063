<template>
    <tree :tree-data="tree" :enable-edit="enableEdit" :enable-link="enableLink"  @newitem="newitemfunc" @delitem="delitemfunc" @edititem="edititemfunc" @itemclick="itemclickfunc"></tree>
</template>
<script>
import Tree from "@/components/NodeTree/Tree";
import {ALL_CATEGORIES_QUERY} from "@/queries/queries";

export default {
    props:{
        enableEdit:{},
        enableLink:{}
    },
    methods:{

      newitemfunc:function(node){
        this.$emit('newitem',node);
      },
      edititemfunc:function(node){
        this.$emit('edititem',node);
      },
      delitemfunc:function(node){
        this.$emit('delitem',node);
      },
      itemclickfunc:function(node)
      {
        this.$emit('itemclick',node);
      }
    },
    emits:{
        itemclick:null
    },
    data(){
        return {
            categories:{}
        }
    },
    components:{
      Tree
    },
    computed:{
        tree:function(){
            if (!this.categories)
                return {};
            
            const data1= JSON.parse(JSON.stringify(this.categories));// deep copy

            if (!data1.reduce)
                return {};
            
            const idMapping = data1.reduce((acc, el, i) => {
                acc[el.id] = i;
                return acc;
            }, {});

            let treeroot = {};
            
            data1.forEach(el => {
                // Handle the root element
                if (el.parent === 0) {
                    treeroot = el;
                    return;
                }
                // Use our mapping to locate the parent element in our data array
                const parentEl = data1[idMapping[el.parent]];
                // Add our current el to its parent's `children` array
                parentEl.children = [...(parentEl.children || []), el];
            });

            return treeroot;   
        }
    },
    apollo:
    {
        objectItem: 
        {
            query: ALL_CATEGORIES_QUERY,
            manual:true,
            result({data, loading}){
            if (!loading)
            {
                this.categories = data.Categories;
            }
            }
        }
    }           
}
</script>