<template>
  <div class="flex flex-wrap mt-4">
    
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0">
        
        
      </div>
      <div class="w-full xl:w-4/12 px-4">
      </div>
    
  </div>
</template>
<script>
export default {

};
</script>
