<template>
<div class="scrollsection">
  <div class="scrollitem">
    tables
  </div>
  <div class="scrollitem yellow">
    tables
  </div>
  <div class="scrollitem">
    tables
  </div>
  <div class="scrollitem yellow">
    tables
  </div>
  <div class="scrollitem">
    tables
  </div>
</div>
</template>
<script>
export default {

};
</script>
<style>
    .scrollsection
    {
        flex-basis: 50%;
        max-height: 100vh;
        overflow-y: scroll;
        scroll-snap-type: y mandatory;
    }
    .yellow
    {
        background-color: khaki;
        opacity: 0.5;
    }
    .scrollitem
    {
        min-height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        scroll-snap-align: start;
    }
</style>