<template>
  <div class="tree">
    <ul class="tree-list mt-0">
      <node-tree :node="treeData" @newitem="newitemfunc" @delitem="delitemfunc" @edititem="edititemfunc" @itemclick="itemclickfunc" :enable-edit="enableEdit" :enable-link="enableLink"></node-tree>
    </ul>
  </div>
</template>

<script>
import NodeTree from "./NodeTree";

export default {
  props: {
    treeData: Object,
    enableEdit: {},
    enableLink: {}
  },
  components: {
    NodeTree
  },
  emits: {
      newitem:null,
      delitem:null,
      edititem:null,
      itemclick:null
  },
  methods:{
      newitemfunc:function(node){
        this.$emit('newitem',node);
      },
      edititemfunc:function(node){
        this.$emit('edititem',node);
      },
      delitemfunc:function(node){
        this.$emit('delitem',node);
      },
      itemclickfunc:function(node)
      {
        this.$emit('itemclick',node);
      }
  }
};
</script>