<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0 mt-10">
    <div class="rounded-t bg-white mb-0 px-6 py-6">   
      <div class="text-center flex justify-between">   
      <h6 class="text-gray-800 text-xl font-bold">Sys settings</h6>
      <button type="button" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-on:click="save()"> Save </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-5 eform">
      <vue-form-generator :schema="schema" :model="model"></vue-form-generator>
    </div>
    <div class="rounded-b bg-white mb-0 px-6 py-6">   
        <div class="text-center flex justify-between">
            <h6></h6>   
            <button type="button" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-on:click="save()"> Save </button>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
.eform{
.vue-form-generator {
    label{
        font-weight: bolder;
    }
    input {
        margin-top:8px;
        background:white;
        border-radius: 2px;
    }
    .form-control
    {
        font-size: larger;
    }
    .CodeMirror-code
    {
      font-size:larger;
    }
}
}
</style>

<script>
import VueFormGenerator from "vue-form-generator";
import gql from 'graphql-tag';

export default {
    components: {
        "vue-form-generator": VueFormGenerator.component
    },
    emits: {
      saveitem:null
    },
    data(){
      return {
        schema:{}
      }
    },
    props:{
      model:{},
      typeName : {},
    },
    methods:{
      save:function(){
        this.$emit('saveitem')
      }
    },
    apollo:
    {
      objectItem: 
      {
        query: gql`query($typeName:String!)  {
          typeDefinition(typeName:$typeName)
          {
              fields
              {
                type,
                inputType,
                label,
                readonly,
                required,
                featured,
                placeholder,
                model,
                disabled
              }
            }
          }`,
        variables()
        {
          return {
            typeName: this.typeName
          }
        },
        manual:true,
        result({data, loading}){
          if (!loading)
          {
            this.schema = {fields:data.typeDefinition.fields};
          }
        }
      }
    }    
};
</script>