<template>
<div>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0 mt-10">
    <div class="rounded-t bg-white mb-0 px-6 py-6">   
      <div class="text-center flex justify-between">   
      <h6 class="text-gray-800 text-xl font-bold">纲要</h6>
      <!-- <button type="button" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-on:click="addnew()"> Add new </button> -->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-5 eform">
        <category-tree @newitem="newitem" @edititem="edititem" @delitem="delitem" :enable-edit="true"></category-tree>
    </div>
    <div class="rounded-b bg-white mb-0 px-6 py-6">   
        <div class="text-center flex justify-between">
            <h6></h6>   
            <!-- <button type="button" class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-on:click="addnew()"> Add new </button> -->
        </div>
    </div>
  </div>
    <!--dialog-->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="isEdit">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <i class="fa fa-edit"></i>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            编辑纲要栏目
                        </h3>
                        <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          <vue-form-generator :schema="schema" :model="model"></vue-form-generator>
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button" v-on:click="saveItem()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Save
                    </button>
                    <button type="button" v-on:click="disableEdit()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>  
</div>
</template>

<script>
import CategoryTree from "@/components/CategoryTree/CategoryTree";
import VueFormGenerator from "vue-form-generator";
import {ALL_CATEGORIES_QUERY,DELETE_CATEGORY_MUTATION,UPDATE_CATEGORY_MUTATION,INSERT_CATEGORY_MUTATION} from "@/queries/queries";

export default {
    components: {
      CategoryTree,
      "vue-form-generator": VueFormGenerator.component
    },
    methods:{
        addnew:function(){
            this.model.name = "";
            this.model.description = "";
            this.model.parent = 1;
            this.model.id = 0;
            this.isEdit = true;
        },
        disableEdit:function(){
            this.isEdit = false;
        },
        newitem:function(node){
            this.model.name = "";
            this.model.description = "";
            this.model.parent = node.id;
            this.model.id = 0;
            this.isEdit = true;
        },
        edititem:function(node){
            this.model.name = node.name;
            this.model.description = node.description;
            this.model.parent = node.parent;
            this.model.id = node.id;
            this.isEdit = true;
        },        
        delitem:function(node){
            this.model=node;
            let updateQuery = DELETE_CATEGORY_MUTATION;
            this.$apollo.mutate(
            {
                mutation: updateQuery,
                variables:{
                    pkid: this.model.id
                },
                update: (cache, { data: { updatedObject } }) => {
                    const cached = cache.readQuery({
                        query: ALL_CATEGORIES_QUERY
                    });
                    cached.Categories = cached.Categories.filter(t=>{ return t.id!=updatedObject.id});
                    cache.writeQuery({ query: ALL_CATEGORIES_QUERY, data: cached })
                }
            }
            );   
        },
        saveItem:function(){
            if (this.model.id==0)
            {
                this.insertItem();
            } else {
                this.updateItem();
            }
        },
        updateItem:function(){
            let updateQuery = UPDATE_CATEGORY_MUTATION;
            this.$apollo.mutate(
            {
                mutation: updateQuery,
                variables:{
                    updateObject: {parent:this.model.parent,name:this.model.name,description:this.model.description,id:this.model.id},
                    pkid: {id:this.model.id}
                },
                update: (cache, { data: { updatedObject } }) => {
                    const cached = cache.readQuery({
                        query: ALL_CATEGORIES_QUERY
                    });
                    const o = cached.Categories.find(t=>t.id===updatedObject.id);
                    o.name = updatedObject.name;
                    o.description = updatedObject.description;
                    cache.writeQuery({ query: ALL_CATEGORIES_QUERY, data:cached })
                }
            }
            ).then((data)=>{
                console.log(data);
                this.isEdit=false;
            });            
        },
        insertItem:function(){
            let updateQuery = INSERT_CATEGORY_MUTATION;
            this.$apollo.mutate(
            {
                mutation: updateQuery,
                variables:{
                    updateObject: {parent:this.model.parent,name:this.model.name,description:this.model.description}
                },
                update: (cache, { data: { updatedObject } }) => {
                    const cached = cache.readQuery({
                        query: ALL_CATEGORIES_QUERY
                    });
                    cached.Categories.push(updatedObject);

                    cache.writeQuery({ query: ALL_CATEGORIES_QUERY, data:cached })
                }
            }
            ).then((data)=>{
                console.log(data);
                this.isEdit=false;
            });            
        }
    },
    data:function(){ 
      return {
        isEdit: false,
        model: {name:"test"},
        schema: {
            fields : [
              {
                type:"input",
                inputType:"text",
                label:"名字",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Name",
                model: "name",
                disabled:false
              },
              {
                type:"MD",
                inputType:"MD",
                label:"描述",
                readonly:false,
                required:true,
                featured:false,
                placeholder:"Description",
                model: "description",
                disabled:false
              }

            ]
            }
        }
    }  
};
</script>
