<template>
  <div>
    <a
      class="text-gray-600 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <h2 class="text-white px-3">{{ $auth.user.name }}</h2>
        <span
          class="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full"
        >
        <div v-if="$auth.isAuthenticated">
            <img :src="$auth.user.picture" class="rounded-full">
        </div>

        <div v-if="!$auth.isAuthenticated">
          <i class="fas fa-user text-2xl rounded-full"></i>
        </div>
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="#/admin/syssettings"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
        Profile
      </a>
      <div class="h-0 my-2 border border-solid border-gray-200" />
      <a
        href="#" v-on:click="logout"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
      >
        Logout
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  watch: {
    '$route' () {
        this.dropdownPopoverShow = false;
    }
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    logout:function(){
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
